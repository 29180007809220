<template>
<section class="page-section" id="services">
  <div class="container px-4 px-lg-5">
    <div class="row gx-4 gx-lg-5 justify-content-center">
      <div class="col-lg-8 text-center">
        <h2 class="text-black mt-0">Nuestros productos</h2>
        <hr class="divider" />
        <p class="text-black-75 mb-4">Si deseas comprar un producto o posees alguna consulta, ¡no dudes en escribirnos!</p>
        <a type="button" class="btn" style="background-color: #25d366; color: white" target="_blank" href="https://api.whatsapp.com/send?phone=56961901927">
        <i class="bi bi-whatsapp"></i>
        ¡Haz tu pedido aquí!
        </a>
        <p class="text-black-75 mb-4"></p>
      </div>
    </div>
    <div id="portfolio">
      <div class="container-fluid p-0">
        <div class="row g-0">
          <div
            v-for="(item, index) in getCurrentPageItems"
            :key="index"
            class="col-lg-4 col-sm-6"
            >
            <!-- Aquí va tu contenido del portfolio -->
            <!-- Ejemplo: -->
            <a class="portfolio-box" @click="openCloseFun(item)" title="Project name">
              <img :src="item.imageSrc" class="img-fluid img-product rounded" :alt="item.title" />
              <div class="portfolio-box-caption">
                <div class="project-category text-white-50">{{ item.format }}</div>
                <div class="project-name">{{ item.title }}</div>
                <div class="project-category text-white-50">${{item.precio}}</div>
              </div>
            </a>
            <!-- Modal -->
            <transition name="fade">
              <div v-if="OpenClose && selectedItem" class="modal-background">
                <div class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style="display:block">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header" style="background-color: #f4623a">
                      <h5 class="modal-title text-white mt-0">{{selectedItem.title}}</h5>
                      <button type="button" class="btn-close" @click="openCloseFun(null)" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <slot>
                        <img :src="selectedItem.imageSrc" :alt="selectedItem.title" class="img-fluid img-modal rounded">
                        <p></p>
                        <p>Marca:{{ selectedItem.marca }}</p>
                        <p>Descripción:{{ selectedItem.descripcion }}</p>
                        <p>Ingredientes:{{ selectedItem.ingredientes }}</p>
                        <p>Precio: ${{selectedItem.precio }}</p>
                      </slot>
                    </div>
                    <!-- BOTON PARA CERRAR
                    <div class="modal-footer">
                      <button type="button" :class="'btn btn-'+variant" @click="openCloseFun(null)">Cerrar</button>
                    </div>
                    -->
                  </div>
                </div>
              </div>
              </div>
              
            </transition>
          </div>
        </div>
        <!-- Botones de paginación -->
        <nav>
          <ul class="pagination justify-content-center">
            <li class="page-item" v-for="page in totalPages" :key="page">
              <a
                class="page-link"
                href="#"
                @click.prevent="setCurrentPage(page)"
                >
              {{ page }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import items from '../assets/js/productos'
export default {
    name: 'MyServices',
    props: {
        visible: Boolean,
        variant: String
    },
  data() {
    return {
      selectedItem: null,
      OpenClose: this.visible,
      items: items,
      itemsPerPage: 3,
      currentPage: 1,
    };
  },
  computed: {
    // Calcular el número total de páginas
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    // Obtener los elementos a mostrar en la página actual
    getCurrentPageItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.items.slice(startIndex, endIndex);
    },
  },
  methods: {
    // Cambiar la página actual
    setCurrentPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    openCloseFun(item){
      this.selectedItem = item;
      this.OpenClose = !this.OpenClose;
    }
  },
  watch: {
        visible: function(newVal, oldVal){
            this.OpenClose = newVal
            console.log('new '+newVal+' == '+oldVal)
        }
    }
  
};
</script>

<style scoped>
  .img-product {
  width: 650px; /* Ajusta la imagen al ancho del contenedor */
  height: 190px;
  overflow: hidden; /* Permite que la altura se ajuste proporcionalmente */
  /* También puedes agregar otros estilos según tus necesidades */
}

  .img-modal {
    max-height: 250px;
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }

  /* Estilos para el fondo oscurecido */
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

/* Estilos adicionales para mantener la consistencia del diseño */
/* Por ejemplo, centrar el contenido en la caja */
</style>