<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
    <div class="container px-4 px-lg-5">
      <img src="../../public/CONGELADOS_DEL_SUR.png" alt="logo" class="logo-image rounded">
      <a class="navbar-brand" href="#page-top">Fábrica de Congelados</a>
      <button class="navbar-toggler" type="button" @click="toggleMenu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" :class="{ 'show': isMenuOpen }" id="navbarResponsive">

        <!--Botones del navbar -->
        <ul class="navbar-nav ms-auto my-2 my-lg-0">
          <li class="nav-item"><a class="nav-link" href="#about" @click="closeMenu">Sobre nosotros</a></li>
          <li class="nav-item"><a class="nav-link" href="#services" @click="closeMenu">Productos</a></li>
          <li class="nav-item"><a class="nav-link" href="#contact" @click="closeMenu">Contacto</a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
    name: 'NavBar',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
  mounted() {
    // Evitar que Bootstrap maneje el evento click en el botón
    const button = document.querySelector(".navbar-toggler");
    if (button) {
      button.removeAttribute("data-bs-toggle");
      button.removeAttribute("aria-controls");
    }
  },
};
</script>

<style scoped>
.logo-image {
  height: 30px; /* Ajusta este valor según tus necesidades */
  max-height: 100%; /* Esto permite que la imagen no sea más alta que el contenedor */
  width: auto; /* Esto mantiene la proporción de aspecto original */
  margin-right: 10px; /* Agrega un margen derecho para separar la imagen del texto */
}
</style>