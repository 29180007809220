<template>
    <section class="page-section bg-primary" id="contact">
            <div class="container px-4 px-lg-5">
                <div class="row gx-4 gx-lg-5 justify-content-center">
                    <div class="col-lg-8 col-xl-6 text-center">
                        <h2 class="text-white mt-0">Contacto</h2>
                        <hr class="divider divider-light" />
                        <p class="text-white-75 mb-4">¿Tienes dudas o deseas realizar un pedido?</p>
                        <p class="text-white-75 mb-4">Puedes escribirnos a través de Whatsapp: </p>
                    </div>
                </div>
                <div class="row gx-4 gx-lg-5 justify-content-center">
                    <div class="col-lg-4 text-center mb-5 mb-lg-0">
                        <div>
                            <!--
                            <a type="button" class="btn btn-secondary" href="mailto:correo01@gmail.com" target="_blank">
                                <i class="bi bi-envelope-at"></i>
                                abc@correo.com
                            </a>
                            -->
                            <a type="button" class="btn" style="background-color: #25d366; color: white" target="_blank" href="https://api.whatsapp.com/send?phone=56961901927">
                                <i class="bi bi-whatsapp"></i>
                                +569 6190 1927
                            </a>
                            <p class="text-white-75 mb-4"></p>
                            <p class="text-white-75 mb-4"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
    name: 'ContactUs'
}
</script>

<style scoped>

</style>