/*
- Listado de productos.
- Cada producto está encerrado entre corchetes { } con sus respectivos datos.
- Para agregar productos, solo copiar uno de los productos de abajo (incluyendo sus corchetes) 
y agregarlo al final del listado. La imagen del producto debe estar guardada en la carpeta img/productos.

Ejemplo de producto:


  {
    imageSrc: require('../img/productos/NOMBRE_IMAGEN.png'),  
    title: 'NOMBRE DEL PRODUCTO',
    format: 'FORMATO DEL PRODUCTO (10 unidades, 5 bolsas, etc...)',
    precio: 'PRECIO DEL PRODUCTO',
    descripcion: 'DESCRIPCION DEL PRODUCTO',
    marca: 'MARCA DEL PRODUCTO',
    ingredientes: 'INGREDIENTES DEL PRODUCTO',
  },

  **IMPORTANTE**: siempre dejar una coma al lado derecho del corchete de abajo }, tal como se muestra en
  el ejemplo.

*/


 
const items = [
  {
      imageSrc: require('../img/productos/empanadas1.png'),
      title: 'Empanadas de cóctel',
      format: '10 UNIDADES',
      precio: '5000',
      descripcion: 'Empanadas de cóctel de 10x10 cm.',
      marca: 'Ariztía',
      ingredientes: 'harina, carne.',
  },
  {
      imageSrc: require('../img/productos/hielo_botilleria.jpg'),
      title: 'Hielo botillerías',
      format: 'Bolsa de 2 KG',
      precio: '3000',
      descripcion: 'Bolsa de hielo ideal para tragos.',
      marca: 'Nestle',
      ingredientes: 'agua.',
  },
  {
      imageSrc: require('../img/productos/piña.jpg'),
      title: 'Pulpa de piña',
      format: '10 UNIDADES',
      precio: '4000',
      descripcion: 'Pulpa de piña natural.',
      marca: 'Hasbro',
      ingredientes: 'piña, azucar.',
  },
  {
      imageSrc: require('../img/productos/4.jpg'),
      title: 'Empanadas de cóctel',
      format: '10 UNIDADES',
      precio: '5000',
      descripcion: 'Empanadas de cóctel de 10x10 cm.',
      marca: 'Ariztía',
      ingredientes: 'harina, carne.',
  },
  {
      imageSrc: require('../img/productos/5.jpg'),
      title: 'Empanadas de cóctel',
      format: '10 UNIDADES',
      precio: '5000',
      descripcion: 'Empanadas de cóctel de 10x10 cm.',
      marca: 'Ariztía',
      ingredientes: 'harina, carne.',
  },
  {
      imageSrc: require('../img/productos/6.jpg'),
      title: 'Empanadas de cóctel',
      format: '10 UNIDADES',
      precio: '5000',
      descripcion: 'Empanadas de cóctel de 10x10 cm.',
      marca: 'Ariztía',
      ingredientes: 'harina, carne.',
  },
  {
    imageSrc: require('../img/productos/empanadas_queso.jpg'),  
    title: 'Empanadas de queso TEST',
    format: '10 UNIDADES/BANDEJA',
    precio: '2500',
    descripcion: 'Empanada cóctel full queso.',
    marca: 'FABRICA DE CONGELADOS',
    ingredientes: 'harina de trigo, queso, almidón, sal, propionato de calcio, agua, aceite vegetal',
  },
];

export default items;
