<template>
    <footer class="bg-light py-5">
            <div class="container px-4 px-lg-5"><div class="small text-center text-muted">Copyright &copy; 2023 - Fábrica de congelados</div></div>
        </footer>
</template>

<script>
export default {
    name: 'FooTer'
}
</script>

<style scoped>

</style>