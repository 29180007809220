<template>
  <body id="body-top">
  
  <NavBar />
  <MastHead />
  <AboutUs />
  <MyServices />
  <ContactUs />
  <FooTer />
  <a href="https://api.whatsapp.com/send?phone=56961901927" class="float" target="_blank">
    <i class="bi bi-whatsapp"></i>
  </a>
  </body>
</template>

<script>
import NavBar from './components/NavBar.vue'
import MastHead from './components/MastHead.vue'
import AboutUs from './components/AboutUs.vue'
import MyServices from './components/MyServices.vue'
import ContactUs from './components/ContactUs.vue'
import FooTer from './components/FooTer.vue'



export default {
  name: 'App',
  components: {
    NavBar,
    MastHead,
    AboutUs,
    MyServices,
    ContactUs,
    FooTer,
  }
}
</script>

<style scoped>
  .float{
	position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>>
