<template>
    <header class="masthead">
            <div class="container px-4 px-lg-5 h-100">
                <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                    <div class="col-lg-8 align-self-end">
                        <h1 class="text-white font-weight-bold">Productos congelados y frescos en un solo lugar</h1>
                        <hr class="divider" />
                    </div>
                    <div class="col-lg-8 align-self-baseline">
                        <p class="text-white-75 mb-5">Somos una empresa ubicada en la comuna de Rancagua, dedicada a la venta y distribución de productos congelados a lo largo de Chile.</p>
                        <a class="btn btn-primary btn-xl" href="#services">Ver productos</a>
                    </div>
                </div>
            </div>
        </header>
</template>

<script>
export default {
    name: 'MastHead'
}
</script>

<style scoped>

</style>