<template>
    <section class="page-section bg-primary" id="about">
            <div class="container px-4 px-lg-5">
                <div class="row gx-4 gx-lg-5 justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="text-white mt-0">Sobre nosotros</h2>
                        <hr class="divider divider-light" />
                        <p class="text-white-75 mb-4">Iniciamos en el 2020, distribuyendo productos congelados exclusivamente a zonas cercanas a la comuna de Rancagua. Actualmente, distribuimos hacia otras regiones del país, gestionando cientos de productos al día, siempre manteniendo su calidad y frescor.</p>
                    </div>
                </div>
            </div>
        </section>
    
</template>

<script>
export default {
    name: 'AboutUs'
}
</script>

<style scoped>

</style>