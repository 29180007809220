import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/styles.css';
import { createApp } from 'vue'
import App from './App.vue'


createApp(App).mount('#app')

import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap.bundle.min'